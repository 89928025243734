import React from "react"
import { Link, graphql } from "gatsby"
import * as Icon from "react-feather"
import { Helmet } from "react-helmet"
import Layout from "../../components/_App/layout"
import SEO from "../../components/_App/seo"
import NavbarNew from "../../components/NavbarNew"
import Footer from "../../components/_App/Footer"
import PageBanner from "../../components/Common/PageBanner"
import GetInTouch from "../../components/get-in-touch"
// import mobileAppIconBig from "../../assets/images/mobile-app-service-icon-big.png"
import mobileAppIconBig from "./mobile-app-service.png"
import webAppIconBig from "./web-app-service.png"

const Service = () => {
  return (
    <Layout>
      <SEO
        title="Services"
        description="Tarkashilpa is a web and mobile application development company with knowledge of latest technologies and innovation. We follow strict app development standards."
        keywords="Web Application Development Services Company Mobile Application Development Services Company"
        url={`/services`}
        ogTwitterCardType="summary"
      />

      <NavbarNew />

      <PageBanner pageTitle="Services" />

      <div className="services-area ptb-80 bg-f7fafd">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-6 services-list-content">
              <div className="section-title text-left">
                <h2>Mobile App Development</h2>
                <div className="bar"></div>
                <div className="services-details-image d-block d-md-none">
                  <img
                    src={mobileAppIconBig}
                    className="animate__animated animate__fadeInUp animate__delay-0.6s"
                    alt="Mobile App Development"
                  />
                </div>
                <p>
                  A mobile app can forge a very intimate connection between your
                  brand and customers. It opens up a world of possibilities for
                  customer engagement. A mobile app has the advantage of
                  availability, your customers can engage while they are
                  traveling or away from their workstations. You can leverage
                  this advantage to reach your customers at the time when they
                  are in the right state of mind to engage.
                </p>
              </div>
              <div className="text-center text-md-start">
                <a
                  className="btn btn-primary"
                  href="/services/mobile-app-development"
                >
                  Learn More
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 services-details-image d-none d-md-block">
              <img
                src={mobileAppIconBig}
                className="animate__animated animate__fadeInUp animate__delay-0.6s"
                alt="Mobile App Development"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bigdata-services-area ptb-80 bg-eef6fd">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-6 services-list-content">
              <div className="section-title text-left">
                <h2>Web App Development</h2>
                <div className="bar"></div>
                <div className="services-details-image d-block d-md-none">
                  <img
                    src={webAppIconBig}
                    className="animate__animated animate__fadeInUp animate__delay-0.6s"
                    alt="Web App Development"
                  />
                </div>
                <p>
                  A web-based solution can automate your business processes and
                  help your team to work efficiently. It brings effective
                  collaboration and let your team work in synergy. It enables
                  easy tracking of your business KRA and drives powerful
                  insights from the business data. A well-designed solution
                  would boost your brand's engagement with your customers and
                  can drastically improve the conversion rate.
                </p>
              </div>
              <div className="text-center text-md-start">
                <a
                  className="btn btn-primary"
                  href="/services/web-app-development"
                >
                  Learn More
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 services-details-image d-none d-md-block">
              <img
                src={webAppIconBig}
                className="animate__animated animate__fadeInUp animate__delay-0.6s"
                alt="Web App Development"
              />
            </div>
          </div>
        </div>
      </div>

      <GetInTouch />

      <Footer />
    </Layout>
  )
}

export default Service
